import React from 'react';
import { Route, Link } from 'react-router-dom';
import {ScanForm} from "../UI/scan/ScanForm";

export const customRoutes = [
    <Route
        key="firstproduct"
        path="/firstproduct"
        component={Link}
    />,
    <Route
        key="scan"
        path="/scan"
        component={ScanForm}
    />,

    // ,
    // <Route
    //     key="new_product"
    //     path="/products/create"
    //     component={ProductList}
    // />
];
