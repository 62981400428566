import React, { Fragment }   from "react";
import { List,
    Datagrid,DeleteButton
} from 'react-admin';
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import {FilterFinal} from "./Filter";
import {useResourceOptions} from "../../hooks";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List  filters={<FilterFinal readableFields={readableFields}/>} {...DefaultListProps({operations})} {...props} bulkActionButtons={false}>
                <Datagrid>
                    {<FieldGuesser  source={'url'}/>}
                    {<FieldGuesser  source={'count'}/>}
                    {<FieldGuesser  source={'totalPages'}/>}
                    {<FieldGuesser  source={'currentPage'}/>}
                    {<FieldGuesser  source={'status'}/>}
                    <DeleteButton undoable={false}/>
                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
