import {
    Button,
    Grid,
    Paper,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef} from 'react';
import { Form,Field } from 'react-final-form';
import { TextField, Input } from 'final-form-material-ui';
import {useMutation, CRUD_UPDATE, useNotify, ReferenceInput, SelectInput} from 'react-admin';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subscription: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        wrap: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    })
);


export const ScanForm = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <MainForm/>
        </div>
    );
};


const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
            marginBottom: theme.spacing(5),
        },
        paperInner: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(3),
            padding: theme.spacing(3),
        },
        buttons: {
            '& > ': {
                marginTop: theme.spacing(3),
                marginRight: theme.spacing(1),
            },
        },
    })
);

const initialValues = {
    sku: '',
    quantityUnshipped: 1,
};

const MainForm = ()  => {
    const classes = useFormStyles();
    const [mutate, { data, loading }] = useMutation();
    const notify = useNotify();
    const skuRef:any = useRef();
    const onSubmit = async (values,form) => {
        // return;
        await mutate (
            {
                type: 'create',
                resource: 'orderitems',
                payload: {data: { ...values } }
            },
            {
                undoable: false,
                action: CRUD_UPDATE,
                onSuccess: response => {
                    form.reset({...values, sku: ''});
                    skuRef.current.focus();
                    // notify('Success !')
                },
                onFailure: error => {
                    form.reset({ ...values, sku: ''});
                    skuRef.current.focus();
                    notify('Failure !')
                }
            }
        );
        return undefined;
    };

    return (
        <Paper className={classes.paper}>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={{ dirtySinceLastSubmit: true, submitting: true, pristine: true, touched: true }}
                // validate={validate}
                render={({ form, handleSubmit, submitting, ...rest }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            noValidate={true}>
                            <Grid container spacing={4}>
                                    <Grid item>
                                        <ReferenceInput
                                             source="supplier"
                                             reference="suppliers"
                                             // filterToQuery={searchText => ({ title: searchText })}
                                            >
                                            <SelectInput optionText="name" />
                                        </ReferenceInput>
                                        <Field
                                            // fullWidth
                                            required
                                            name="sku"
                                            component={TextField}
                                            type="text"
                                            label="UPC"
                                            autoFocus
                                            inputRef={skuRef}
                                            autoComplete={'off'}
                                        />
                                        <Field
                                            // fullWidth
                                            parse={(value) => parseInt(value)}
                                            required
                                            name="quantityUnshipped"
                                            component={Input}
                                            type="number"
                                            label="Stock"
                                            autoComplete={'off'}
                                        />
                                    </Grid>
                                    <Grid item className={classes.buttons}>
                                        <Button variant="contained" color="primary" type="submit" disabled={submitting || loading}>
                                            Submit
                                        </Button>
                                    </Grid>

                            </Grid>
                        </form>
                    )
                }}
            />
            {data && data.sku && <OrderInfo orderItem={data}/>}
            {data && data.supplierProductId && <ProductInfo product={data}/>}
        </Paper>
    );
};

const OrderInfo = ({orderItem}) => {
    return (
        <>
            <div>UPC: {orderItem.sku}</div>
            <div>Title: {orderItem.title}</div>
            <div>Order: {orderItem.order.channelOrderId}</div>
        </>
    )
};


const ProductInfo = ({product}) => {
    return (
        <>
            <div>UPC: {product.supplierProductId}</div>
            <div>Stock: {product.stock}</div>
        </>
    )
};
